import React, { useState } from "react";
import GlegooBold from "../../assets/fonts/Glegoo/Glegoo-Bold.ttf";
import GlegooRegular from "../../assets/fonts/Glegoo/Glegoo-Regular.ttf";
import GilroyBold from "../../assets/fonts/gilroy/Gilroy-Bold.ttf";
import GilroyMedium from "../../assets/fonts/gilroy/Gilroy-Medium.ttf";
import GilroyRegular from "../../assets/fonts/gilroy/Gilroy-Regular.ttf";
import GilroySemiBold from "../../assets/fonts/gilroy/Gilroy-SemiBold.ttf";

import {
  Document,
  Page,
  View,
  Text,
  Image,
  Font,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import bharat from "../../assets/NewImage/bharat.png";
import bharatBlack from "../../assets/NewImage/bharatBlack.png";
import bharatandilca from "../../assets/NewImage/bharatandilca.png";
import logo from "../../assets/NewImage/logoR.png";
import qrcode from "../../assets/NewImage/qrcode.png";
import sign2 from "../../assets/NewImage/sign2.png";
import sign3 from "../../assets/NewImage/sign3.png";
import bgImage from "../../assets/NewImage/bgImage.png";
import leftArrow from "../../assets/images/leftArrow.png";
import rightArrow from "../../assets/images/rightArrow.png";
// import sigen1 from "../../assets/images/sigen1.png";
import Footer from "../footer/Footer";
import moment from "moment";
import { subtractOneWorkingDayIndia } from "./helper";

Font.register({
  family: "Glegoo-Regular",
  src: GlegooRegular,
});

Font.register({
  family: "Glegoo-Bold",
  src: GlegooBold,
});
Font.register({
  family: "Gilroy-Regular",
  src: GilroyRegular,
});
Font.register({
  family: "Gilroy-Medium",
  src: GilroyMedium,
});
Font.register({
  family: "Gilroy-Bold",
  src: GilroyBold,
});
Font.register({
  family: "Gilroy-SemiBold",
  src: GilroySemiBold,
});

const styles = StyleSheet.create({
  backgroundImage: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
    height: "380.882px",
    width: "389px",
  },
  tabelRecord: {
    flexDirection: "row",
    borderTopWidth: 0.5,
    borderColor: "#050340",
  },
  tabelCellView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    borderRightWidth: 0.5,
    borderColor: "#050340",
    textAlign: "center",
    padding: 6,
  },
  tabelCellCombineView: {
    flex: 1,
    borderRightWidth: 0.5,
    borderColor: "#050340",
    textAlign: "center",
    padding: 6,
  },
  tabelCellCombineLastView: {
    flex: 1,
    borderColor: "#050340",
    textAlign: "center",
    padding: 6,
  },
  tabelCellCobineView: {
    borderRightWidth: 0.5,
    borderColor: "#050340",
    textAlign: "center",
    width: "40%",
  },
  tabelCellLastView: {
    flex: 1,
    textAlign: "center",
    padding: 6,
    justifyContent: "center",
    alignItems: "center",
  },
});

const CertificateGradeII = (props) => {
  // const [isBefore, setIsBefore] = useState(false);
  const propsData = props.propsData;
  const createdAtDate = moment(propsData && propsData.created_at).format("YYYY-MM-DD");
  // const createdAtDate = moment("2021-04-04").format("YYYY-MM-DD");

  const shouldHideView =
    createdAtDate >= "2022-01-30" && createdAtDate < "2023-03-21";
  const isBeforeDate = createdAtDate < "2022-01-30";

  var version1 = false
  var version2 = false
  var visibleIlacLogo = false

  if (moment(createdAtDate).isBefore("01-01-2024")) {
    console.log("Yes befor");
    version1 = true;
  }
  if (moment(createdAtDate).isAfter("01-01-2024") && moment(createdAtDate).isBefore("01-01-2025")) {
    console.log("Yes befor");
    version2 = true;
  }
  if (moment(createdAtDate).isSameOrAfter("01-01-2025")) {
    visibleIlacLogo = true;
  }

  return (
    <>
      <View
        style={{
          border: "1px double #050340",
          margin: "10px",
        }}
      >
        <View
          style={{
            border: "1px double #050340",
            margin: "4px",
            padding: "10px 0 0 0",
            height: "100%",
          }}
        >
          <View style={styles.backgroundImage}>
            <Image src={bgImage} style={styles.backgroundImage} />
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0px 15px",
            }}
          >
            <View
              style={{
                width: "235",
                height: "95px",
              }}
            >
              <Image src={logo} alt="logo" />
              <Text
                style={{
                  color: "#2B2A28",
                  textAlign: "right",
                  fontFamily: "Gilroy-Medium",
                  fontSize: "17px",
                  paddingTop: '4px'
                }}
              >
                (Laboratory Division)
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }} >
              {/* {shouldHideView ? null : (
                <> */}
              {
                visibleIlacLogo ?
                  <Image
                    src={bharatandilca}
                    alt="bharat"
                    style={{
                      width: "160px",
                      height: "87px",
                    }}
                  /> : <>
                    <Image
                      src={bharatBlack}
                      alt="bharat"
                      style={{
                        width: "66px",
                        height: "75px",
                      }}
                    />
                    <Text
                      style={{
                        fontSize: "8px",
                        textAlign: "center",
                        marginTop: "5px",
                        fontFamily: "Gilroy-Bold",
                        color: "#2B2A28",
                      }}
                    >
                      Certi. No : TC - {isBeforeDate ? 8938 : 11481}
                    </Text>
                  </>
              }
              {/* </>
              )} */}
            </View>
          </View>
          <View
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
              color: "#035141",
              marginTop: "20px",
              padding: "0px 15px",
            }}
          >
            <View style={{ textAlign: "right", width: "60%" }}>
              <Text
                style={{
                  fontSize: "24px",
                  fontFamily: "Glegoo-Bold",
                }}
              >
                Test Report
              </Text>
            </View>
            <View style={{ width: "16%", textAlign: "right" }}>
              <Text
                style={{
                  color: "#035141",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "12px",
                }}
              >
                Standard Sand ( Grade-II )
              </Text>
            </View>
          </View>
          <View
            style={{
              borderTop: "1px solid #035141",
              margin: "0px 15px",
            }}
          ></View>
          <View
            style={{
              width: "26%",
              border: "1.3px solid #035141",
              backgroundColor: "#035141",
              margin: "-1px 15px 0px auto",
            }}
          ></View>
          <View
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              flexDirection: "row",
              marginTop: "20px",
              fontSize: "8px",
              color: "#2B2A28",
              padding: "0px 15px",
            }}
          >
            <View style={{ width: "100%" }}>
              {propsData && propsData.ulr_number &&
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    ULR No. :
                  </Text>
                  <Text
                    style={{
                      fontFamily: "Gilroy-Bold",
                      marginLeft: "2.5px",
                    }}
                  >
                    {propsData && propsData.ulr_number && propsData.ulr_number}
                  </Text>
                </View>
              }

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "2px",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  Name of Customer :
                </Text>
                <Text
                  style={{
                    fontFamily: "Gilroy-Medium",
                    marginLeft: "2.5px",
                    width: "180px"
                  }}
                >
                  Pinal Corporation {propsData.tested_for ? `(${propsData.tested_for})` : ""}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "2px",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  Contact Information of Customer :
                </Text>
                <Text
                  style={{
                    fontFamily: "Gilroy-Bold",
                    marginLeft: "2.5px",
                  }}
                >
                  inquiry@pinalsand.com
                </Text>
              </View>
              {propsData && propsData.sample_id &&
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "2px",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    Sample ID :
                  </Text>
                  <Text
                    style={{
                      fontFamily: "Gilroy-Bold",
                      marginLeft: "2.5px",
                    }}
                  >
                    {propsData && propsData.sample_id && propsData.sample_id || "-"}
                  </Text>
                </View>
              }
              {
                propsData && propsData.sample_id && <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "2px",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    Sample Req. Slip No. :
                  </Text>
                  <Text
                    style={{
                      fontFamily: "Gilroy-Bold",
                      marginLeft: "2.5px",
                    }}
                  >
                    {/* {propsData &&
                  propsData.sample_request_slip_number &&
                  propsData.sample_request_slip_number} */}
                    {propsData && propsData.sample_id && propsData.sample_id || "-"}
                  </Text>
                </View>
              }

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "2px",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  Sample Weight :
                </Text>
                <Text
                  style={{
                    fontFamily: "Gilroy-Bold",
                    marginLeft: "2.5px",
                  }}
                >
                  500gm
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "2px",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  Condition of Sample :
                </Text>
                <Text
                  style={{
                    fontFamily: "Gilroy-Bold",
                    marginLeft: "2.5px",
                  }}
                >
                  Good and Moisture Free
                </Text>
              </View>
            </View>
            <View style={{ width: "100%" }}>
              {propsData &&
                (propsData.date_of_report_issued || propsData.created_at) &&
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    Date of Report Issued :
                  </Text>
                  <Text
                    style={{
                      fontFamily: "Gilroy-Bold",
                      marginLeft: "2.5px",
                    }}
                  >
                    {version1 ? propsData && propsData.created_at && moment(propsData.created_at).format(
                      "DD MMM YYYY"
                    ) || "-" : propsData &&
                    propsData.date_of_report_issued &&
                    moment(propsData.date_of_report_issued).format(
                      "DD MMM YYYY"
                    ) || "-"}
                  </Text>
                </View>
              }
              {propsData && (propsData.sample_id || propsData.batch_number) &&
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "2px",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    Test Report No. :
                  </Text>
                  <Text
                    style={{
                      fontFamily: "Gilroy-Bold",
                      marginLeft: "2.5px",
                    }}
                  >
                    {version1 ? propsData && propsData.batch_number && propsData.batch_number : (version2 ? propsData && propsData.sample_id && propsData.sample_id || propsData && propsData.batch_number && propsData.batch_number : propsData && propsData.sample_id && propsData.sample_id)}
                  </Text>
                </View>
              }
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "2px",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  Lab Temp :
                </Text>
                <Text
                  style={{
                    fontFamily: "Gilroy-Bold",
                    marginLeft: "2.5px",
                  }}
                >
                  26 °C 70% rh
                </Text>
              </View>
              {
                propsData &&
                (propsData.date_of_testing || propsData.created_at) &&
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "2px",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    Sample Received Date :
                  </Text>
                  <Text
                    style={{
                      fontFamily: "Gilroy-Bold",
                      marginLeft: "2.5px",
                    }}
                  >
                    {version1 ? propsData && propsData.created_at && moment(subtractOneWorkingDayIndia(propsData.created_at)).format("DD MMM YYYY") || "-" : propsData &&
                      propsData.date_of_testing &&
                      moment(propsData.date_of_testing).format("DD MMM YYYY")}
                  </Text>
                </View>
              }

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "2px",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  Sample Detail :
                </Text>
                <Text
                  style={{
                    fontFamily: "Gilroy-Bold",
                    marginLeft: "2.5px",
                  }}
                >
                  Quartz Powder
                </Text>
              </View>
              {
                propsData &&
                (propsData.date_of_testing || propsData.created_at) &&
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "2px",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    Date of Testing :
                  </Text>
                  <Text
                    style={{
                      fontFamily: "Gilroy-Bold",
                      marginLeft: "2.5px",
                    }}
                  >
                    {version1 ? propsData && propsData.created_at && moment(subtractOneWorkingDayIndia(propsData.created_at)).format("DD MMM YYYY") || "-" : propsData &&
                      propsData.date_of_testing &&
                      moment(propsData.date_of_testing).format("DD MMM YYYY")}
                  </Text>
                </View>
              }

            </View>
          </View>
          <View
            style={{
              border: "0.5px solid #050340",
              marginTop: 10,
              marginLeft: "15px",
              marginRight: "15px",
            }}
          >
            <Text
              style={{
                fontSize: "10px",
                textAlign: "center",
                padding: 6,
                fontFamily: "Gilroy-Bold",
              }}
            >
              Test Result
            </Text>
            <View style={styles.tabelRecord}>
              <View style={styles.tabelCellView}>
                <Text style={{ fontSize: "8px" }}>Parameter</Text>
              </View>
              <View style={styles.tabelCellCobineView}>
                <View style={{ borderBottom: "0.5 solid #050340" }}>
                  <Text
                    style={{
                      fontSize: "10px",
                      paddingTop: 7,
                      paddingBottom: 4,
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    Mechanical
                  </Text>
                </View>
                <Text style={{ fontSize: "8px", padding: 6 }}>
                  Particle Size Analysis
                </Text>
                <View style={styles.tabelRecord}>
                  <View style={styles.tabelCellCombineView}>
                    <Text style={{ fontSize: "8px" }}>% Pass (1.00 mm)</Text>
                  </View>
                  <View style={styles.tabelCellCombineLastView}>
                    <Text style={{ fontSize: "8px" }}>% Retain (0.500 mm)</Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  textAlign: "center",
                  width: "39.95%",
                }}
              >
                <View style={{ borderBottom: "0.5 solid #050340" }}>
                  <Text
                    style={{
                      fontSize: "10px",
                      paddingTop: 7,
                      paddingBottom: 4,
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    Chemical
                  </Text>
                </View>
                <View
                  style={{
                    height: "42px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <View style={{ flex: 1, justifyContent: "center" }}>
                    <Text
                      style={{
                        fontSize: "8px",
                        padding: "0 8px",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      The Loss of Mass On <br /> Extraction With Hot HCL
                    </Text>
                  </View>
                  <View
                    style={{
                      borderLeftWidth: 0.5,
                      borderColor: "#050340",
                      flex: 1,
                      justifyContent: "center",
                    }}
                  >
                    <Text style={{ fontSize: "8px" }}> Organic Impurities</Text>
                  </View>
                </View>
              </View>

              {/* <View style={styles.tabelCellView}>
                <Text style={{ fontSize: "8px" }}>
                  Loss of Mass On Extraction Against Hot HCL *
                </Text>
              </View>
              <View style={styles.tabelCellLastView}>
                <Text style={{ fontSize: "8px" }}>Organic Impurities</Text>
              </View> */}
            </View>
            <View style={styles.tabelRecord}>
              <View style={styles.tabelCellView}>
                <Text style={{ fontSize: "8px" }}>Acceptance</Text>
              </View>
              <View style={styles.tabelCellView}>
                <Text style={{ fontSize: "8px" }}>100</Text>
              </View>
              <View style={styles.tabelCellView}>
                <Text style={{ fontSize: "8px" }}>99-100</Text>
              </View>
              <View style={styles.tabelCellView}>
                <Text style={{ fontSize: "8px" }}>{`${"< 0.250"}`}</Text>
              </View>
              <View style={styles.tabelCellLastView}>
                <Text style={{ fontSize: "8px" }}>Absent/Present</Text>
              </View>
            </View>
            <View style={styles.tabelRecord}>
              <View style={styles.tabelCellView}>
                <Text style={{ fontSize: "8px" }}>Test Result</Text>
              </View>
              <View style={styles.tabelCellView}>
                <Text style={{ fontSize: "8px" }}>
                  {propsData &&
                    propsData.pass_through &&
                    propsData.pass_through}
                </Text>
              </View>
              <View style={styles.tabelCellView}>
                <Text style={{ fontSize: "8px" }}>
                  {propsData && propsData.retain_on && propsData.retain_on}
                </Text>
              </View>
              <View style={styles.tabelCellView}>
                <Text style={{ fontSize: "8px" }}>
                  {propsData && propsData.hcl && propsData.hcl}
                </Text>
              </View>
              <View style={styles.tabelCellLastView}>
                <Text style={{ fontSize: "8px" }}>Absent</Text>
              </View>
            </View>
            <View style={styles.tabelRecord}>
              <View style={styles.tabelCellView}>
                <Text style={{ fontSize: "8px" }}>Test Method</Text>
              </View>
              <View style={styles.tabelCellView}>
                <Text style={{ fontSize: "8px" }}>
                  IS: 2386 ( Part I ) : 2010
                </Text>
              </View>
              <View style={styles.tabelCellView}>
                <Text style={{ fontSize: "8px" }}>
                  IS: 2386 ( Part I ) : 2010
                </Text>
              </View>
              <View style={styles.tabelCellView}>
                <Text style={{ fontSize: "8px" }}>IS 650 : 2023</Text>
              </View>
              <View style={styles.tabelCellLastView}>
                <Text style={{ fontSize: "8px" }}>
                  IS: 2386 ( Part II ) : 1983
                </Text>
              </View>
            </View>
            <View style={styles.tabelRecord}>
              <View style={styles.tabelCellView}>
                <Text style={{ fontSize: "8px" }}>Specification</Text>
              </View>
              <View style={styles.tabelCellView}>
                <Text style={{ fontSize: "8px" }}>IS 650 : 2023</Text>
              </View>
              <View style={styles.tabelCellView}>
                <Text style={{ fontSize: "8px" }}>IS 650 : 2023</Text>
              </View>
              <View style={styles.tabelCellView}>
                <Text style={{ fontSize: "8px" }}>IS 650 : 2023</Text>
              </View>
              <View style={styles.tabelCellLastView}>
                <Text style={{ fontSize: "8px" }}>IS 650 : 2023</Text>
              </View>
            </View>
          </View>
          <View style={{ marginTop: "10px" }}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100px",
                margin: "auto",
              }}
            >
              <View>
                <Image src={leftArrow} style={{ width: "12px" }} />
              </View>
              <Text
                style={{
                  fontFamily: "Gilroy-Bold",
                  fontSize: "8px",
                  textAlign: "center",
                  marginTop: "3px",
                }}
              >
                End of the Report
              </Text>
              <View>
                <Image src={rightArrow} style={{ width: "12px" }} />
              </View>
            </View>
          </View>
          <View
            style={{
              marginLeft: "15px",
              marginRight: "15px",
              marginTop: "5px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View>
              <View
                style={{
                  height: "86px",
                  width: "134px",
                  // border: "1px solid #E9E9ED",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Image src={sign2} style={{ width: "87px", height: "39px" }} />
              </View>
              <View
                style={{
                  width: "60%",
                  margin: "0 auto",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    marginTop: "5px",
                    fontSize: "8px",
                    color: "#2B2A28",
                    fontFamily: "Gilroy-SemiBold",
                  }}
                >
                  Reviewed By
                </Text>
                <Text
                  style={{
                    fontSize: "8px",
                    color: "#2B2A28",
                    fontFamily: "Gilroy-SemiBold",
                  }}
                >
                  {" "}
                  Barach Siddharthsinh
                </Text>
                <Text
                  style={{
                    fontSize: "8px",
                    color: "#2B2A28",
                    fontFamily: "Gilroy-SemiBold",
                  }}
                >
                  {" "}
                  [Quality Manager]
                </Text>
              </View>
            </View>
            <View>
              <View
                style={{
                  height: "86px",
                  width: "134px",
                  // border: "1px solid #E9E9ED",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Image src={sign3} style={{ width: "79px", height: "33px" }} />
              </View>
              <View
                style={{
                  width: "60%",
                  margin: "0 auto",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    marginTop: "5px",
                    fontSize: "8px",
                    color: "#2B2A28",
                    fontFamily: "Gilroy-SemiBold",
                  }}
                >
                  Authorized Signatory
                </Text>
                <Text
                  style={{
                    fontSize: "8px",
                    color: "#2B2A28",
                    fontFamily: "Gilroy-SemiBold",
                  }}
                >
                  {" "}
                  Pinal Patel
                </Text>
                <Text
                  style={{
                    fontSize: "8px",
                    color: "#2B2A28",
                    fontFamily: "Gilroy-SemiBold",
                  }}
                >
                  {" "}
                  [CEO]
                </Text>
              </View>
            </View>
          </View>
          {/* <View
            style={{
              color: "#2B2A28",
              fontSize: "8px",
              marginTop: "13px",
              padding: "0px 15px",
            }}
          >
            <Text
              style={{
                fontFamily: "Gilroy-Regular",
              }}
            >
              * Remarks :
            </Text>
            <View
              style={{
                fontFamily: "Gilroy-Bold",
                padding: "4px 0px 0px 5px",
                display: "flex",
                rowGap: "3px",
              }}
            >
              <Text>
                • The Sample Confirming to IS 650 : 2023:1991 in All Respect.
              </Text>
              <Text>• Test Sieves Confirming to IS 460 Part 1.</Text>
              <Text>
                • Date of expiry : 7 Years From Date of the Manufacturing.
              </Text>
            </View>
          </View> */}
          <View
            style={{
              position: "absolute",
              bottom: "50px",
              left: 0,
              right: 0,
              margin: "8px 15px",
              fontSize: "7px",
              fontFamily: "Gilroy-Medium",
              color: "black",
              lineHeight: "1.2px",
            }}
          >
            <Text>Note :</Text>
            <Text>
              1. The test report and result relate to the particular
              specimen/sample(s) of the material as delivered/received, at the
              time of testing in the laboratory.
            </Text>
            <Text>
              2. The sampling is not carried by Pinal Corporation (laboratory division), testing as per sample provided by the customer.
            </Text>
            <Text>
              3. This report may not be reproduced in part, without the permission of the laboratory.
            </Text>
            <Text>4. Any correction invalidate this Test Report.</Text>
            <Text>5. Test Sieves Confirming to IS: 460 ( Part 1 ) - 2020</Text>
          </View>
          <Footer />
        </View>
      </View>
    </>
  );
};

export default CertificateGradeII;
